<template>
  <section class="py-8 bg-white">
    <div class="container px-4 mx-auto">
      <button @click="showAddUserModal = true"
        class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Benutzer
        erstellen</button>
      <div class="overflow-x-auto mb-16">
        <div class="relative inline-block min-w-full border border-gray-100 rounded-5xl overflow-hidden">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr class="text-sm uppercase font-heading text-left">
                <th class="flex items-center pl-10 py-6 pr-12 md:pr-40 lg:pr-52 2xl:pr-64">
                  <span>Benutzername</span>
                </th>
                <th class="py-4 pr-24">Admin</th>
                <th class="py-4 pr-16">Anwendungen</th>
                <th class="py-4 pr-16"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user._id">
                <td class="p-0">
                  <div class="flex items-center pl-10 pr-4 h-20 border-b border-gray-100">

                    <div class="flex">
                      <div>
                        <p class="font-heading font-medium">{{ user.username }}</p>
                        <p class="text-sm font-normal text-darkBlueGray-400">{{ user.email }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <div v-if="user.admin" class="flex items-center h-20 border-b border-gray-100"><a
                      class="inline-block py-1 px-3 text-sm leading-5 font-medium bg-green-200 hover:bg-opacity-100 bg-opacity-80 rounded-full">Ja</a>
                  </div>
                  <div v-else class="flex items-center h-20 border-b border-gray-100"><a
                      class="inline-block py-1 px-3 text-sm leading-5 font-medium bg-red-200 hover:bg-opacity-100 bg-opacity-80 rounded-full">Nein</a>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex flex-items py-4 pr-16 border-b border-gray-100">
                    <div class="flex mr-auto"><a v-if="user.applications[0]"
                        class="inline-block py-2 px-4 mr-2 text-darkBlueGray-400 font-medium border-2 border-gray-100 hover:border-gray-200 rounded-full">{{ getApplicationNameById(user.applications[0] || undefined) }}</a><a
                        v-if="user.applications[1]"
                        class="inline-block py-2 px-4 text-darkBlueGray-400 font-medium border-2 border-gray-100 hover:border-gray-200 rounded-full"
                        href="#">+{{ user.applications.length - 1 }}</a><a v-if="!user.applications[0]"
                        class="inline-block py-2 px-4 mr-2 text-darkBlueGray-400 font-medium border-2 border-gray-100 hover:border-gray-200 rounded-full">None</a>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                    <button @click="openEditUserModal(user._id)" class="font-heading font-medium">Bearbeiten</button>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>



  <div>
    <vue-final-modal v-model="showAddUserModal" classes="modal-container" content-class="modal-content"
      :esc-to-close="true" :lock-scroll="false" @closed="onModalClosed()">
      <form @submit="createUser()">

        <div class="max-w-md lg:mx-auto">
          <form action="">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Benutzername (Erforderlich)" v-model="addUserUsername">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Passwort (Erforderlich)" v-model="addUserPassword">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="email"
                placeholder="E-Mail (Optional / Empfohlen)" v-model="addUserEmail">
            </div>
            <div class="mb-6">
              <label class="p-4">
                <input type="checkbox" id="admin" value="admin" v-model="addUserAdminChecked">
                <span class="ml-1">Admin</span>
              </label>
            </div>
            <div v-for="application in applications" :key="application._id">
              <label class="pl-4">
                <input type="checkbox" :id="application._id" :value="application._id"
                  v-model="addUserCheckedApplications">
                <span class="ml-1">{{ application.name }}</span>
              </label>
            </div>

            <div class="mb-4">
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="createUser()"
                class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Benutzer
                erstellen</button>
            </div>
            <p v-if="addUserShowSuccess"
              class="mb-4 w-full py-2 bg-green-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ addUserSuccessMessage }}</p>
            <p v-if="addUserShowError"
              class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ addUserErrorMessage }}</p>
          </form>

        </div>
      </form>
    </vue-final-modal>
  </div>




    <div>
    <vue-final-modal v-model="showEditUserModal" classes="modal-container" content-class="modal-content"
      :esc-to-close="true" :lock-scroll="false" @closed="onModalClosed()">
      <form @submit="editUser()">

        <div class="max-w-md lg:mx-auto">
          <form action="">
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Username (Required)" v-model="editUserUsername">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="text"
                placeholder="Password (Required)" v-model="editUserPassword">
            </div>
            <div class="mb-4">
              <input class="w-full p-4 text-xs font-semibold leading-none bg-white rounded outline-none" type="email"
                placeholder="E-Mail (Optional / Empfohlen)" v-model="editUserEmail">
            </div>
            <div class="mb-6">
              <label class="p-4">
                <input type="checkbox" id="admin" value="admin" v-model="editUserAdminChecked">
                <span class="ml-1">Admin</span>
              </label>
            </div>
            <div v-for="application in applications" :key="application._id">
              <label class="pl-4">
                <input type="checkbox" :id="application._id" :value="application._id"
                  v-model="editUserCheckedApplications">
                <span class="ml-1">{{ application.name }}</span>
              </label>
            </div>

            <div class="mb-4">
            </div>
            <div class="flex justify-between items-center text-center">
              <button @click="editUser()"
                class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Benutzer
                bearbeiten (Speichern)</button>
            </div>
                        <div class="flex justify-between items-center text-center">

                             <button @click="deleteUser()"
                class="mb-4 w-full py-4 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50">Benutzer
                löschen</button></div>
            <p v-if="editUserShowSuccess"
              class="mb-4 w-full py-2 bg-green-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ editUserSuccessMessage }}</p>
            <p v-if="addUserShowError"
              class="mb-4 w-full py-2 bg-red-600 rounded text-sm font-bold text-gray-50 text-center">
              {{ editUserErrorMessage }}</p>
          </form>

        </div>
      </form>
    </vue-final-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  //import copy from 'copy-to-clipboard';

  export default {
    name: 'Applications',
    data() {
      return {
        users: null,
        showAddUserModal: false,
        applications: undefined,
        addUserUsername: undefined,
        addUserPassword: undefined,
        addUserEmail: undefined,
        addUserAdminChecked: false,
        addUserCheckedApplications: [],
        addUserSuccessMessage: undefined,
        addUserShowSuccess: false,
        addUserErrorMessage: undefined,
        addUserShowError: false,

        userToEdit: undefined,
        showEditUserModal: false,
        editUserUsername: undefined,
        editUserPassword: undefined,
        editUserEmail: undefined,
        editUserAdminChecked: false,
        editUserCheckedApplications: [],
        editUserSuccessMessage: undefined,
        editUserShowSuccess: false,
        editUserErrorMessage: undefined,
        editUserShowError: false,

      }
    },
    mounted() {
      this.getUsers()
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/applications/`)
        .then(response => {
          this.applications = response.data
        })
        .catch(error => {
          console.log('error')
          console.log(error)

        })
    },
    methods: {
      onModalClosed() {
        console.log('asdf')
      },
      openEditUserModal(userId) {
        const user = this.users.find(element => element._id == userId);

        this.editUserUsername = user.username
        this.editUserPassword = 'XXXXXXXXXXXXXX'
        this.editUserEmail = user.email
        this.editUserAdminChecked = user.admin
        this.editUserCheckedApplications = JSON.parse(JSON.stringify(user.applications))

        this.editUserShowError = false
        this.editUserErrorMessage = undefined

        this.editUserShowSuccess = false
        this.editUserSuccessMessage = undefined

        this.userToEdit = userId
        this.showEditUserModal = true
      },
      getUsers() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_URL}/users/`)
          .then(response => {
            console.log(response)
            this.users = response.data
          })
      },
      async createUser() {
        return await axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/users/`, {
            "username": this.addUserUsername,
            "password": this.addUserPassword,
            "email": this.addUserEmail,
            "applications": this.addUserCheckedApplications,
            "admin": this.addUserAdminChecked,
          })
          .then(() => {
            this.addUserShowError = false
            this.addUserErrorMessage = false

            this.addUserSuccessMessage = 'User successfully added'
            this.addUserShowSuccess = true

            this.addUserUsername = undefined
            this.addUserPassword = undefined
            this.addUserEmail = undefined
            this.adduserCheckedApplications = undefined
            this.addUserAdminChecked = false

            this.getUsers()
          })
          .catch(error => {
            this.addUserErrorMessage = error.response.data.message
            this.addUserShowError = true
            console.log(error.response)
          })
      },
      async deleteUser() {
        axios.delete(`${process.env.VUE_APP_BACKEND_URL}/users/${this.userToEdit}`)
        .then(() => {
            this.editUserShowError = false
            this.editUserErrorMessage = false

            this.editUserSuccessMessage = 'User successfully deleted'
            this.editUserShowSuccess = true

            this.editUserUsername = undefined
            this.editUserPassword = undefined
            this.editUserEmail = undefined
            this.editUserCheckedApplications = undefined
            this.editUserAdminChecked = false

            this.getUsers()
        })
        .catch(error => {
            this.editUserErrorMessage = error.response.data.message
            this.editUserShowError = true
            console.log(error.response)
        })
      },
      async editUser() {
        axios
          .put(`${process.env.VUE_APP_BACKEND_URL}/users/${this.userToEdit}`, {
            "username": this.editUserUsername,
            "password": this.editUserPassword,
            "email": this.editUserEmail || undefined,
            "applications": this.editUserCheckedApplications,
            "admin": this.editUserAdminChecked,
          })
          .then(() => {
            this.editUserShowError = false
            this.editUserErrorMessage = false

            this.editUserSuccessMessage = 'User successfully edited'
            this.editUserShowSuccess = true

            this.editUserUsername = undefined
            this.editUserPassword = undefined
            this.editUserEmail = undefined
            this.editUserCheckedApplications = undefined
            this.editUserAdminChecked = false

            this.getUsers()
          })
          .catch(error => {
            this.editUserErrorMessage = error.response.data.message
            this.editUserShowError = true
            console.log(error.response)
          })
      },

      getApplicationNameById(applicationId) {
        let application = "Error"
        try {
          application = (this.applications.find(element => element._id == applicationId)).name
        } catch (error) {
          console.log(error)
        }
        

        return application
      }
    },
  }
</script>

<style scoped>
  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::v-deep .modal-content {
    position: relative;
    width: fit-content;
    min-width: 30%;
    padding: 16px;
    overflow: auto;
    background-color: #f9fafb;
    border-radius: 4px;
  }

  .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
</style>

<style scoped>
  .dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
  }
</style>